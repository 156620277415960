@import './invoy-theme/colors.scss';
@import './invoy-theme/font-families.scss';
@import './invoy-theme/spacers.scss';
@import './invoy-theme/breakpoints.scss';

// header customization
$headings-font-family: $font-family-serif;
$headings-color: $primary;

$enable-dark-mode: false;

// form input
$form-floating-label-transform: scale(0.67) translateY(-0.5rem)
  translateX(0.15rem);

// table customization
$table-striped-bg: $gray-100;
$table-group-separator-color: $gray-300;

@import './invoy-theme/utilities.scss';
@import '~bootstrap/scss/bootstrap.scss';
