@import './invoy-theme/font-families.scss';
@import './invoy-theme/colors.scss';
@import './invoy-theme/breakpoints.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family-sans-serif;
  background-color: $i-mint-green;
  overflow: auto;
}

a {
  color: $indigo;
  text-decoration: none;
}

.link-primary:hover {
  cursor: zoom-in;
  transition: 0.5s;
  opacity: 0.4;
}

.bg-secondary {
  background-color: hsla(210, 65%, 52%, 0.2) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

* {
  box-sizing: border-box;
}

/* Texts */

.text-danger {
  color: $i-red;
}

.fs-small {
  font-size: 13px !important;
  line-height: 16px !important;
}

.fs-hero {
  font-size: 19px !important;
  line-height: 23px !important;
}

.fs-hero-mobile {
  font-size: 33px !important;
  line-height: 40px !important;
}

/* Buttons */

.btn {
  border-radius: 4px;
  font-size: 17px;
  line-height: 19px;

  &.btn-primary {
    color: white;
  }

  &.btn-light {
    color: #318ef6;
    background: #fff;
    border: 1px solid rgba(161, 167, 188, 0.33);
  }

  &.btn-secondary {
    background: #649372;
    color: white;
  }

  &.btn-sm {
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
  }

  &.btn-lg {
    border-radius: 5px;
    font-size: 18px;
    line-height: 22px;
  }
}

.btn-primary {
  background-color: linear-gradient(120.8deg, #1e71d3 -51.29%, #348ffb 78.39%);
  transition: background-color 500ms;

  &.active,
  &:active,
  &.hover,
  &:hover {
    transition: filter 400ms;
    filter: invert(0.9);
    background-color: linear-gradient(
      120.8deg,
      #1e71d3 -51.29%,
      #1e71d3 78.39%
    );
  }

  &.submit-button {
    min-width: 135px;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

button {
  border: 0;
  background: none;
}

/* RESPONSIVE SIZING */

/* default - mobile */
.container-width {
  padding-left: 1rem;
  padding-right: 1rem;
}
.responsive-p {
  font-size: 0.75rem;
}

/* tablet */
@media (min-width: $md) {
  .container-width {
    padding: 0;
    width: 75%;
  }

  .responsive-p {
    font-size: 1rem;
  }
}

/* desktop */
@media (min-width: $lg) {
  .container-width {
    padding: 0;
    width: 50%;
  }

  .responsive-p {
    font-size: 1rem;
  }
}

/* Overriding default Bootstrap form control styling */

/* PAGE STYLING */
body.page {
  background-color: $primary;
}

/* OFF CANVAS OVERRIDES */
.offcanvas-end {
  width: 210px;
}

/* BS ACCORDION OVERRIDES */
.accordion-button {
  padding: 25px 0;

  h6 {
    margin: 0;
  }

  &:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;

    &::after {
      background-image: url('/icons/angle-down.svg');
    }
  }

  &::after {
    background-image: url('/icons/angle-down.svg');
    width: 21px;
    height: 11px;
    margin-right: 15px;
  }
}

.accordion-body {
  padding: 0 54px 25px 0;
  font-size: 12px;

  ul,
  ol {
    padding-left: 24px;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

input.form-control {
  border: 0;
  border-bottom: 1px solid $gray-500;
  border-radius: 0;
}

.form-control {
  border: 0;
  border-bottom: 1px solid $gray-500;
  border-radius: 0;
  margin-bottom: 20px;
}

.form-floating .form-select {
  // Vertically center to the selection options not the whole input height (includes the floating label)
  //  Normally this doesn't look too bad, but without input borders it looks like it is too high up
  background-position: right 0.75rem bottom 12px;
}

/* TOGGLE SWITCH */

.toggle-switch {
  .btn {
    padding: 6px 13px;
    font-size: 15px;
    border-radius: 4px;
    box-shadow: none;

    &.btn-sm {
      font-size: 16px;
      line-height: 22px;
    }

    &.btn-lg {
      font-size: 18px;
      line-height: 22px;
    }

    &.btn-white {
      color: $i-denim-blue;
    }

    &.btn-primary.active {
      background-color: $i-denim-blue;
      border-color: $i-denim-blue;
    }
  }
}

/* CHECK BOXES */
.form-check-input:checked {
  background-color: $i-denim-blue;
  border-color: $i-denim-blue;
}

/* FORM CHECK */
.form-check {
  p {
    font-family: $font-family-serif;
  }
}

/* Floating label wrap fix */
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  width: 150%;
}

.form-floating > .form-control:focus {
  border-color: #abd1fb;
  box-shadow: 0 0 0 0.2rem rgb(49 142 246 / 25%);
}

.form-floating > label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

/* FONTS */

.ff-sans-serif {
  font-family: $font-family-sans-serif !important;
}

.ff-serif {
  font-family: $font-family-serif !important;
}

.ff-mono {
  font-family: $font-family-monospace !important;
}

/* MISSING TW BOOTSTRAP STYLES*/

.bg-white {
  background-color: #ffffff !important;
}

.stripe-input-padding {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
